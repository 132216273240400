<template>
  <div>
    <b-container fluid>
      <b-row style="padding-top: 15px">
        <b-col cols="12" md="12" lg="8" xl="8">
          <div
            style="
              width: 100%;
              height: 40px;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              background-color: ;
            "
          >
            <h6
              style="font-weight: 700; color: #9c4098; font-size: 22px; margin-bottom: 0"
            >
              Penyebab Pasien Meninggal
            </h6>
          </div>
        </b-col>
        <b-col cols="12" md="12" lg="4" xl="4">
          <div style="display: flex; justify-content: flex-end">
            <b-button variant="primary" @click="pindahList()">
              <h6 class="m-0 p-0 text-light">
                <b-icon icon="list-task"></b-icon>
                Lihat Semua
              </h6>
            </b-button>
          </div>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12" xl="12" class="p-0">
          <hr />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <b-card>
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <b-form-group label-cols-md="4">
                  <template v-slot:label> Cari Pasien </template>
                  
                  <!-- <Multiselect
                    v-model="pencari_data"
                    :options="option_cari_pasien"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="eRm / NIK / No. Asuransi Pasien"
                    size="sm"
                    track-by="rm_id"
                    label="nama_lengkap"
                    :loading="is_loading"
                    :internal-search="false"
                    @search-change="cekCariPasien"
                  >
                    <template #no-result>
                      Data pasien tidak ditemukan.
                    </template>
                  </Multiselect> -->
                  
                  <Multiselect
                    v-model="input_nomor"
                    :options="option_cari_pasien"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="eRm / NIK / No. Asuransi Pasien"
                    size="sm"
                    @change="getDataPasien()"
                    :disabled="!!form_penyebab_kematian.kunjungan_id"
                  ></Multiselect>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label> ID <sup class="text-danger">*</sup> </template>
                  <b-form-input
                    v-model="$v.form_penyebab_kematian.tanda_pengenal.$model"
                    :state="checkIfValid('tanda_pengenal')"
                    type="text"
                    placeholder="otomatis"
                    disabled
                  ></b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label> NIK </template>
                  <!-- <Multiselect
                    v-model="form_penyebab_kematian.tanda_pengenal"
                    :options="option_nik"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="No. Induk Kependudukan"
                    size="sm"
                  ></Multiselect> -->
                  <b-form-input
                    v-model="$v.form_penyebab_kematian.nik_alm.$model"
                    :state="checkIfValid('nik_alm')"
                    type="text"
                    disabled
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label> No. KK </template>
                  <!-- <Multiselect
                    :options="option_kk"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="No. Kartu Keluarga"
                    size="sm"
                  ></Multiselect> -->
                  <b-form-input
                    v-model="$v.form_penyebab_kematian.kk_alm.$model"
                    :state="checkIfValid('kk_alm')"
                    type="text"
                    disabled
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Nama <sup class="text-danger">*</sup>
                  </template>
                  <b-form-input
                    v-model="$v.form_penyebab_kematian.nama_alm.$model"
                    :state="checkIfValid('nama_alm')"
                    type="text"
                    disabled
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Nama Kepala Keluarga
                  </template>
                  <b-form-input
                    v-model="$v.form_penyebab_kematian.nama_kepala_keluarga.$model"
                    :state="checkIfValid('nama_kepala_keluarga')"
                    type="text"
                  >
                  </b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label> Jenis Kelamin </template>
                  <b-form-radio-group
                    id=""
                    v-model="$v.form_penyebab_kematian.jenis_kelamin_alm.$model"
                    :state="checkIfValid('jenis_kelamin_alm')"
                  >
                    <b-form-radio value="L" class="mt-1">
                      <span>Laki-laki</span>
                    </b-form-radio>
                    <b-form-radio value="P">
                      <span>Perempuan</span>
                    </b-form-radio>
                  </b-form-radio-group>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Tempat Lahir <sup class="text-danger">*</sup>
                  </template>
                  <b-form-input
                    v-model="$v.form_penyebab_kematian.tempat_lahir_alm.$model" 
                    :state="checkIfValid('tempat_lahir_alm')"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Tanggal Lahir <sup class="text-danger">*</sup>
                  </template>
                  <DatePicker
                    v-model="$v.form_penyebab_kematian.tanggal_lahir_alm.$model"
                    format="YYYY-MM-DD"
                    type="date"
                    placeholder="-- Pilih tanggal --"
                    style="width: 100%;"
                    :state="checkIfValid('tanggal_lahir_alm')"
                  ></DatePicker>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Provinsi <sup class="text-danger">*</sup>
                  </template>
                  <Multiselect
                    v-model="obj_provinsi"
                    :options="option_prov"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    size="sm"
                    track-by="ms_provinsi_id"
                    label="nama_provinsi"
                  ></Multiselect>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Kota/Kab <sup class="text-danger">*</sup>
                  </template>
                  <Multiselect
                    v-model="obj_kota"
                    :options="option_kabkot"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    size="sm"
                    track-by="ms_kota_id"
                    label="nama_kota"
                  ></Multiselect>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Kecamatan <sup class="text-danger">*</sup>
                  </template>
                  <Multiselect
                    v-model="obj_kecamatan"
                    :options="option_kec"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    size="sm"
                    track-by="ms_kecamatan_id"
                    label="nama_kecamatan"
                  ></Multiselect>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Kelurahan/Desa <sup class="text-danger">*</sup>
                  </template>
                  <Multiselect
                    v-model="obj_kelurahan"
                    :options="option_keldes"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    size="sm"
                    track-by="ms_kelurahan_id"
                    label="nama_kelurahan"
                  ></Multiselect>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label> Dusun </template>
                  <b-form-input
                    v-model="$v.form_penyebab_kematian.nama_dusun_alm.$model"
                    type="text"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Alamat <sup class="text-danger">*</sup>
                  </template>
                  <b-form-textarea 
                    v-model="$v.form_penyebab_kematian.alamat_alm.$model"
                    rows="3"
                    :state="checkIfValid('alamat_alm')"
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr />
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <h6 class="custom-title">Telah dinyatakan meninggal pada</h6>
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Tanggal Meninggal <sup class="text-danger">*</sup>
                  </template>
                  <DatePicker
                    v-model="$v.form_penyebab_kematian.tanggal_meninggal_alm.$model"
                    format="YYYY-MM-DD"
                    type="date"
                    placeholder="-- Pilih tanggal --"
                    style="width: 100%;"
                    :state="checkIfValid('tanggal_meninggal_alm')"
                  ></DatePicker>
                </b-form-group>
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Jam Meninggal <sup class="text-danger">*</sup>
                  </template>
                  <DatePicker
                    v-model="$v.form_penyebab_kematian.jam_meninggal_alm.$model"
                    format="HH:mm"
                    type="time"
                    placeholder="-- Pilih jam --"
                    style="width: 100%;"
                    :state="checkIfValid('jam_meninggal_alm')"
                    :open.sync="openDatePickerTime"
                  >
                    <template v-slot:footer="{ emit }">
                      <div style="text-align: left">
                        <button class="mx-btn mx-btn-text" @click="selectTimeNow(emit)">
                          Sekarang
                        </button>
                      </div>
                    </template>
                  </DatePicker>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label> Lokasi Meninggal <sup class="text-danger">*</sup> </template>
                  <b-form-input
                    v-model="$v.form_penyebab_kematian.lokasi_meninggal.$model"
                    type="text" 
                    placeholder="Lokasi meninggal"
                    :state="checkIfValid('lokasi_meninggal')"
                  ></b-form-input>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Pada Usia <sup class="text-danger">*</sup>
                  </template>
                  <b-form-input v-model="$v.form_penyebab_kematian.usia_meninggal.$model" type="text" disabled></b-form-input>
                </b-form-group>
              </b-col>

              <!-- ========================== PENYEBAB KEMATIAN ========================== -->
              <b-col cols="12" sm="12" md="12" lg="6" xl="6">
                <h6 class="custom-title">Penyebab kematian</h6>
                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Sebab Langsung <sup class="text-danger">*</sup>
                  </template>
                  <Multiselect
                    v-model="$v.form_obj.obj_sebab_langsung.$model"
                    :options="option_diagnosa"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    size="sm"
                    track-by="ms_diagnosa_id"
                    label="nm_diag"
                    :custom-label="nmWithKd"
                    :state="checkIfObjValid('obj_sebab_langsung')"
                  ></Multiselect>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Sebab Tidak Langsung <sup class="text-danger">*</sup>
                  </template>
                  <Multiselect
                    v-model="$v.form_obj.obj_sebab_tidak_langsung.$model"
                    :options="option_diagnosa"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    size="sm"
                    track-by="ms_diagnosa_id"
                    label="nm_diag"
                    :custom-label="nmWithKd"
                    :state="checkIfObjValid('obj_sebab_tidak_langsung')"
                  ></Multiselect>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label> Penyakit Penyerta </template>
                  <Multiselect
                    v-model="$v.form_obj.obj_penyakit_penyerta.$model"
                    :options="option_diagnosa"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih --"
                    size="sm"
                    track-by="ms_diagnosa_id"
                    label="nm_diag"
                    :custom-label="nmWithKd"
                    :state="checkIfObjValid('obj_penyakit_penyerta')"
                  ></Multiselect>
                </b-form-group>

                <b-form-group label-cols-md="4">
                  <template v-slot:label>
                    Pemeriksa <sup class="text-danger">*</sup>
                  </template>
                  <Multiselect
                    v-model="$v.form_obj.obj_pemeriksa_kematian.$model"
                    :options="option_pemeriksa"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    placeholder="-- Pilih pemeriksa --"
                    size="sm"
                    track-by="tenaga_medis_id"
                    label="nama_tenaga_medis"
                    :state="checkIfObjValid('obj_pemeriksa_kematian')"
                  ></Multiselect>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <hr />
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="12" sm="12" md="12" lg="12" xl="12">
                <div style="width: 100%; display: flex; justify-content: flex-end">
                  <b-button variant="light" style="margin-right: 10px" @click="resetDataPenyebabKematian">Reset</b-button>
                  <b-button variant="primary" style="margin-right: 10px">Cetak</b-button>

                  <b-button :disabled="busy || !isValid || !isValidObj" variant="success" @click="saveDataPenyebabKematian">Simpan</b-button>
                </div>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/locale/id";
import "vue2-datepicker/index.css";

import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
export default {
  components: {
    Multiselect,
    DatePicker,
  },
  name: "input_penyebab_kematian",
  data() {
    return {
      option_cari_pasien: [],
      option_nik: [],
      option_kk: [],
      option_nik_pelapor: [],

      // option_langsung: [],
      // option_tdk_langsung: [],
      // option_penyerta: [],
      option_diagnosa: [],
      option_pemeriksa: [],

      form_penyebab_kematian: {
        kunjungan_id: "",
        rm_id: "",
        ms_kelurahan_id: "",

        tanda_pengenal: "",
        nik_alm: "",
        kk_alm: "",
        nama_alm: "",
        nama_kepala_keluarga: "",
        jenis_kelamin_alm: "",
        tempat_lahir_alm: "",
        tanggal_lahir_alm: "",

        nama_dusun_alm: "",
        alamat_alm: "",
        tanggal_meninggal_alm: new Date(),
        jam_meninggal_alm: new Date(),
        lokasi_meninggal: "",
        
        tahun_usia_meninggal_alm: "",
        bulan_usia_meninggal_alm: "",
        hari_usia_meninggal_alm: "",
        usia_meninggal: "",

        pemeriksa_kematian_id: "",
        diag_penyebab_langsung_id: "",
        diag_penyebab_tidak_langsung_id: "",
        diag_penyakit_id: ""
      },
      input_nomor: "",

      obj_provinsi: [],
      obj_kota: [],
      obj_kecamatan: [],
      obj_kelurahan: [],
      option_prov: [],
      option_kabkot: [],
      option_kec: [],
      option_keldes: [],

      form_obj: {
        obj_sebab_langsung: [],
        obj_sebab_tidak_langsung: [],
        obj_penyakit_penyerta: [],
        obj_pemeriksa_kematian: [],
      },

      busy: false,
      openDatePickerTime: false,
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.form_penyebab_kematian, null, 4);
    },
    isValid() {
      return !this.$v.form_penyebab_kematian.$invalid;
    },
    isDirty() {
      return this.$v.form_penyebab_kematian.$anyDirty;
    },
    
    formStringObj() {
      return JSON.stringify(this.form_obj, null, 4);
    },
    isValidObj() {
      return !this.$v.form_obj.$invalid;
    },
    isDirtyObj() {
      return this.$v.form_obj.$anyDirty;
    },
  },
  async mounted() {
    await this.getProvinsi()
    await this.getPemeriksa()
    await this.getDiagnosa()

    if (this.$route.params.kunjungan_id) {
      // console.log(this.$route.params);
      this.form_penyebab_kematian.kunjungan_id = this.$route.params.kunjungan_id
      this.getDataKunjungan()
    }
  },
  mixins: [validationMixin],
  validations: {
    form_penyebab_kematian: {
      kunjungan_id: { required },
      rm_id: {},
      ms_kelurahan_id: {},

      tanda_pengenal: { required },
      nik_alm: { required },
      kk_alm: { required },
      nama_alm: { required },
      nama_kepala_keluarga: {},
      jenis_kelamin_alm: { required },
      tempat_lahir_alm: { required },
      tanggal_lahir_alm: { required },

      nama_dusun_alm: {},
      alamat_alm: { required },
      tanggal_meninggal_alm: { required },
      jam_meninggal_alm: { required },
      lokasi_meninggal: { required },
      usia_meninggal: {},

      diag_penyebab_langsung_id: {},
      diag_penyebab_tidak_langsung_id: {},
      diag_penyakit_id: {},
      pemeriksa_kematian_id: {},
    },

    form_obj : {
      obj_sebab_langsung: { required },
      obj_sebab_tidak_langsung: { required },
      obj_penyakit_penyerta: { required },
      obj_pemeriksa_kematian: { required },
    }
  },
  methods: {
    hitungUsia() {
      let a = this.$moment(this.form_penyebab_kematian.tanggal_meninggal_alm);
      let b = this.$moment(this.form_penyebab_kematian.tanggal_lahir_alm);

      // console.log(this.form_penyebab_kematian.tahun_usia_meninggal_alm);
      // console.log(this.form_penyebab_kematian.bulan_usia_meninggal_alm);
      // console.log(this.form_penyebab_kematian.hari_usia_meninggal_alm);

      
      if (a && b) {
        let diffDuration = this.$moment.duration(a.diff(b));
        // console.log(diffDuration.years());
        // console.log(diffDuration.months());
        // console.log(diffDuration.days());
        this.form_penyebab_kematian.usia_meninggal = `${diffDuration.years()} tahun ${diffDuration.months()} bulan ${diffDuration.days()} hari`;
      }
    },
    checkIfValid(fieldName) {
      const field = this.$v.form_penyebab_kematian[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    checkIfObjValid(fieldName) {
      const field = this.$v.form_obj[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    pindahList() {
      let vm = this;
      vm.$router.push({
        path: "/pasien/pasien_meninggal",
        // query: { id_ba : data.item.id },
      });
    },
    async getPemeriksa() {
      const vm = this
      try {
        const res = await vm.$axios.post('/tenaga_medis/list')
        if (res.data.status == 200 && res.data.message == 'sukses') {
          vm.option_pemeriksa = res.data.data
        }
      } catch (err) {
        console.log(err);        
      }
    },
    async getDiagnosa() {
      const vm = this
      try {
        const res = await vm.$axios.post('/ms_diagnosa/list')
        if (res.data.status == 200 && res.data.message == 'sukses') {
          vm.option_diagnosa = res.data.data
        }
      } catch (err) {
        console.log(err);        
      }
    },
    async getDataPasien() {
      const vm = this;
      let req = {
        nomor: vm.input_nomor,
      };
      try {
        const res = await vm.$axios.post(`/rm/details_rm_non_bpjs_by_nomor`, req);
        if (res.data.status == 200 && res.data.message == "sukses") {
          vm.form_penyebab_kematian = res.data.data;
        }
      } catch (err) {
        console.log(err);
        vm.triggerAlert({
          variant: "danger",
          msg: "TERJADI GANGGUAN PADA JARINGAN",
          showing: true,
        });
      }
    },
    async getDataKunjungan() {
      const vm = this
      // nik_alm, kk_alm, nama_alm, jenis_kelamin_alm, 
      // tempat_lahir_alm, tanggal_lahir_alm, alamat_alm, 
      // tanggal_meninggal_alm,jam_meninggal_alm,tahun_usia_meninggal_alm,
      // bulan_usia_meninggal_alm,hari_usia_meninggal_alm,pemeriksa_kematian_id,
      // diag_penyebab_langsung_id,diag_penyebab_tidak_langsung_id,diag_penyakit_id, 
      // ms_kelurahan_id ,nama_kepala_keluarga,rm_id,kunjungan_id ,nama_dusun_alm
      try {
        const res = await vm.$axios.post("/kunjungan/list", vm.form_penyebab_kematian)
        if (res.data.status == 200 && res.data.message == 'sukses') {
          // console.log("data kunjungan", res.data.data);
          let x = res.data.data[0]
          vm.form_penyebab_kematian.rm_id = x.rm_id
          vm.form_penyebab_kematian.tanda_pengenal = "KTP"
          vm.form_penyebab_kematian.nik_alm = x.nik
          vm.form_penyebab_kematian.kk_alm = x.no_kk
          vm.form_penyebab_kematian.nama_alm = x.nama_lengkap
          vm.form_penyebab_kematian.jenis_kelamin_alm = x.jenis_kelamin
          vm.form_penyebab_kematian.tempat_lahir_alm = x.tempat_lahir
          vm.form_penyebab_kematian.tanggal_lahir_alm = new Date(x.tanggal_lahir)
          vm.form_penyebab_kematian.alamat_alm = x.alamat_ktp
          vm.form_penyebab_kematian.alamat_sekarang_alm = x.alamat_sekarang
          // vm.form_penyebab_kematian.jam_meninggal_alm = x.jam_meninggal
          vm.form_penyebab_kematian.tahun_usia_meninggal_alm = x.usia_tahun
          vm.form_penyebab_kematian.bulan_usia_meninggal_alm = x.usia_bulan
          vm.form_penyebab_kematian.hari_usia_meninggal_alm = x.usia_hari
          // vm.form_penyebab_kematian.pemeriksa_kematian_id = x.ms_dokter_id
          // vm.form_penyebab_kematian.diag_penyebab_langsung_id = x.diag_penyebab_langsung_id
          // vm.form_penyebab_kematian.diag_penyebab_tidak_langsung_id = x.diag_penyebab_tidak_langsung_id
          // vm.form_penyebab_kematian.diag_penyakit_id = x.diag_penyakit_id
          vm.form_penyebab_kematian.nama_kepala_keluarga = x.nama_kepala_keluarga
          // vm.form_penyebab_kematian.nama_dusun_alm = x.nama_dusun
          vm.form_penyebab_kematian.ms_kelurahan_id = x.ms_kelurahan_id

          vm.cariKelurahan(x.ms_kelurahan_id)
          vm.hitungUsia()
        }
      } catch (err) {
        console.log(err);
        vm.triggerAlert({
          variant: "danger",
          msg: "TERJADI GANGGUAN PADA JARINGAN",
          showing: true,
        });
      }
    },
    async cariKelurahan(val) {
      const vm = this
      try {
        let res = await vm.$axios('/ms_kelurahan/details_by_id/' + val)
        // console.log("cari kelurahan", res);
        if (res.data.status == 200 && res.data.message == 'sukses') {
          let x = res.data.data[0]
          vm.obj_provinsi = vm.option_prov.find(opt => opt.ms_provinsi_id == x.ms_provinsi_id)

          await vm.getKota(vm.obj_provinsi)
          vm.obj_kota = vm.option_kabkot.find(opt => opt.ms_kota_id == x.ms_kab_kota_id)

          await vm.getKecamatan(vm.obj_kota)
          vm.obj_kecamatan = vm.option_kec.find(opt => opt.ms_kecamatan_id == x.ms_kecamatan_id)

          await vm.getKelurahan(vm.obj_kecamatan)
          vm.obj_kelurahan = vm.option_keldes.find(opt => opt.ms_kelurahan_id == x.ms_kelurahan_id)
        }
      } catch (err) {
        console.log(err);
      }
    },
    async getProvinsi() {
      const vm = this
      try {
        let provinsi = await vm.$axios.post('/ms_provinsi/list')
        vm.option_prov = provinsi.data.status == 200 ? provinsi.data.data : []
      } catch (err) {
        console.log(err);
      }
    },
    async getKota(val) {
      const vm = this
      // console.log("kota", val);
      let x = val ? { ms_provinsi_id: val.ms_provinsi_id } : null
      try {
        let kota = await vm.$axios.post('/ms_kota/list', x)
        vm.option_kabkot = kota.data.status == 200 ? kota.data.data : []
      } catch (err) {
        console.log(err);
      }
    },
    async getKecamatan(val) {
      const vm = this
      // console.log("kecamatan", val);
      let x = val ? { ms_kota_id: val.ms_kota_id } : null
      try {
        let kecamatan = await vm.$axios.post('/ms_kecamatan/list', x)
        vm.option_kec = kecamatan.data.status == 200 ? kecamatan.data.data : []
      } catch (err) {
        console.log(err);
      }
    },
    async getKelurahan(val) {
      const vm = this
      // console.log("kelurahan", val);
      let x = val ? { ms_kecamatan_id: val.ms_kecamatan_id } : null
      try {
        let kelurahan = await vm.$axios.post('/ms_kelurahan/list', x)
        vm.option_keldes = kelurahan.data.status == 200 ? kelurahan.data.data : []
      } catch (err) {
        console.log(err);
      }
    },
    async saveDataPenyebabKematian() {
      const vm = this
      vm.form_penyebab_kematian.diag_penyebab_langsung_id = vm.form_obj.obj_sebab_langsung.ms_diagnosa_id
      vm.form_penyebab_kematian.diag_penyebab_tidak_langsung_id = vm.form_obj.obj_sebab_tidak_langsung.ms_diagnosa_id
      vm.form_penyebab_kematian.diag_penyakit_id = vm.form_obj.obj_penyakit_penyerta.ms_diagnosa_id
      vm.form_penyebab_kematian.pemeriksa_kematian_id = vm.form_obj.obj_pemeriksa_kematian.tenaga_medis_id
      vm.form_penyebab_kematian.jam_meninggal_alm = vm.$moment(vm.form_penyebab_kematian.jam_meninggal_alm).format('HH:mm')
      // console.log(vm.form_penyebab_kematian);

      try {
        const res = await vm.$axios.post('/meninggal/register', vm.form_penyebab_kematian)
        if (res.data.status == 200 && res.data.message == 'sukses') {
          vm.triggerAlert({
            variant: "success",
            msg: "BERHASIL MENAMBAHKAN DATA",
            showing: true,
          });
          vm.resetDataPenyebabKematian()
          vm.pindahList()
        } else {
          console.log("else", res.data);
          vm.triggerAlert({
            variant: "warning",
            msg: _.toUpper(res.data.message),
            showing: true,
          });
        }
      } catch (err) {
        console.log(err);
        vm.triggerAlert({
          variant: "danger",
          msg: "TERJADI GANGGUAN PADA JARINGAN",
          showing: true,
        });
      }
    },
    nmWithKd ({ nm_diag, kd_diag }) {
      return `${nm_diag} — [${kd_diag}]`
    },
    triggerAlert(event) {
      let vm = this;
      vm.$store.commit("set_alert", event);
    },
    selectTimeNow(emit) {
      let now = new Date()
      let hours = now.getHours()
      let minute = now.getMinutes()
      if (minute < 10) {
        this.form_penyebab_kematian.jam_meninggal_alm = `${hours}:0${minute}`
      } else {
        this.form_penyebab_kematian.jam_meninggal_alm = `${hours}:${minute}`
      }
      this.openDatePickerTime = false
      // emit(time)
    },
    resetDataPenyebabKematian() {
      this.$v.$reset();
      this.form_penyebab_kematian = {
        kunjungan_id: "",

        tanda_pengenal: "",
        nik_alm: "",
        kk_alm: "",
        nama_alm: "",
        nama_kepala_keluarga: "",
        // jenis_kelamin_alm: "",
        tempat_lahir_alm: "",
        tanggal_lahir_alm: "",

        nama_dusun_alm: "",
        alamat_alm: "",
        tanggal_meninggal_alm: new Date(),
        jam_meninggal_alm: new Date(),
        lokasi_meninggal: "",

        pemeriksa_kematian_id: "",
        diag_penyebab_langsung_id: "",
        diag_penyebab_tidak_langsung_id: "",
      };
      this.form_obj = {
        obj_sebab_langsung: [],
        obj_sebab_tidak_langsung: [],
        obj_penyakit_penyerta: [],
        obj_pemeriksa_kematian: []
      }
    },
  },
};
</script>

<style scoped>
.btn-primary {
  background-color: #9c4098;
}
</style>
